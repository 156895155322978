import Cookies from 'js-cookie';

export default class CookiesBar{

  constructor(selector) {
    this.element = $(selector);
  }

  show() {
    $(this.element).show();
  }

  hide() {
    $(this.element).hide();
    Cookies.set('cookiespolicy-hidden', 1);
  };
}
