import Swiper from 'swiper';

export default class SwiperSlider {
  /**
   * @param {SwiperOptions} defaultParams
   */
  constructor(defaultParams = {}) {
    this.defaultParams = defaultParams;
    /**
     * @type {Array.<Swiper>}
     */
    this.swipers = [];
  }

  /**
   * Initialize each Swiper matched by selector if it wasn't initialized before
   * @see getParams
   *
   * @param {String} selector
   * @param {SwiperOptions} params
   * @returns {Array.<Swiper>}
   */
  init(selector, params = {}) {
    const swipers = [];
    $(selector).each((index, item) => {
      if (!$(item).hasClass('swiper-container-initialized')) {
        let concreteSlider = new Swiper(item, this.getParams(params, item));
        swipers.push(
          concreteSlider
        );
      }
    });

    this.swipers.push(...swipers);
    return swipers;
  }

  /**
   * Returns params passed to Swiper constructor
   * Params are combination of 3 level cascade hierarchy:
   * defaultParams - 1 level params passed to SwiperSlider object in constructor
   * initParams - 2 level params passed to init function, properties will override defaultParams
   * HTMLElement data-swiper attribute - overrides all other params as lowest possible level, except navigation parameter
   * @see getNavigationParam.
   * @param {Object} initParams
   * @param {HTMLElement} item
   * @returns {object}
   */
  getParams(initParams, item) {
    const htmlObjectParams = $(item).data('swiper') || {};
    htmlObjectParams.navigation = this.getNavigationParam(initParams, item);

    return {...this.defaultParams, ...initParams, ...htmlObjectParams};
  }

  /**
   * Function set proper navigation parameter for Swiper slider.
   * When no navigation param, function fild .swiper-button-* classes inside .swiper-container
   * There is function type available for navigation.nextEl and navigation.prevEl, which gets HTMLElement of swiper as param, to set custom navigations for multiple initialized elements.
   * @param initParams
   * @param item
   * @returns {{}|{nextEl: jQuery, prevEl: jQuery}}
   */
  getNavigationParam(initParams, item){
    let navigation = null;
    if (!initParams || typeof initParams.navigation === 'undefined') {
      navigation = {
        nextEl: $(item).parent().find('.swiper-button-next'),
        prevEl: $(item).parent().find('.swiper-button-prev'),
      }
    }else{
      navigation = {...initParams.navigation};
      if(typeof initParams.navigation.nextEl === 'function' ){
        navigation.nextEl = initParams.navigation.nextEl(item);
      }

      if(typeof initParams.navigation.prevEl === 'function' ){
        navigation.prevEl = initParams.navigation.prevEl(item);
      }
      // console.log(navigation);
    }

    return navigation;
  }
}
