import Wishlist from './Wishlist';

$(document).ready(function () {
  const wishlist = new Wishlist();
  prestashop.wishlist = wishlist;

  $(document).on('click', '.wishlist-add', function (e) {
    e.preventDefault();
    var $el = $(this);
    wishlist
      .add($el.attr('href'))
      .then((response) => {
        $el.removeClass('wishlist-add');
        $el.addClass('wishlist-remove');
      });
  });

  $(document).on('click', '.wishlist-remove', function (e) {
    e.preventDefault();
    var $el = $(this);
    wishlist
      .remove($el.attr('href'))
      .then((response) => {
        $el.removeClass('wishlist-remove');
        $el.addClass('wishlist-add');
      });
  });

    $(document).on('click', '.js-wishlist-remove-with-miniature', function (e) {
        e.preventDefault();
        const $el = $(this);
        wishlist
            .remove($el.attr('href'))
            .then((response) => {
                $el.closest('.product-miniature').remove();
            });
    });

  wishlist.on('afterRequest', (response) => {
  })
});
