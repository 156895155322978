export default class WishlistBridge {
  add(url) {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: url + '&wishListAdd',
        async: true,
        success: function (response)  {
          resolve(response);
        },
        error: function(response){
          reject(response);
        }
      });
    });
  }

  remove(url){
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: url + '&wishListRemove',
        async: true,
        success: function (response)  {
          resolve(response);
        },
        error: function(response){
          reject(response);
        }
      });
    });
  }
}
