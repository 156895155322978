/* global $, prestashop */
$(document).ready(function () {
  $(document).on('change', '.js-qty-input', function () {
    let value = parseInt($(this).val());
    let minValue = parseInt($(this).data('min-cart-qty'));
    let previousValue = parseInt($(this).data('previous-value'));

    if (value % minValue !== 0) {
      let newValue = value >= previousValue ?
        value + minValue - (value % minValue) :
        value - (value % minValue);

      $(this).val(newValue);
      $(this).data('previous-value', newValue);
    } else {
      $(this).data('previous-value', value);
    }
  });

  $(document).on('click', '.js-mass-add-submit', function (e) {
    e.preventDefault();

    var div = $('.mass-add');

    $.ajax({
      url: div.data('action'),
      method: 'post',
      data: div.closest('form').serialize(),
      dataType: 'json',
      success: function (response) {
        if(response.success) {
          prestashop.emit('updateCart', {
            reason: {
              cart: prestashop.cart,
              idProduct: response.id_product,
              idProductAttribute: response.id_product_attribute,
              linkAction: 'add-to-cart',
            }
          });
        } else {
          alert(div.data('error-text'));
        }
      }
    });
  });
});
